import * as React from "react"

import Layout from "components/layout"
import { LeagueTable } from "components/leagueTable/leagueTable"
import { tables, tablesPast } from "data/tables"
import Headline from "components/general/headline"

const TablesPage = () => {
  const seo = {
    metaTitle: "Tabelle",
    metaDescription:
      "Aktuelle Tabellen der Ligen in denen die Faustballer des MTV Vorsfelde teilnehmen",
  }

  return (
    <Layout seo={seo} showSidebar={false}>
      <div className="main-box">
        <Headline
          headline="Mannschaften"
          subHeadline="Aktive Faustball Mannschaften des MTV Vorsfelde"
        />

        <section aria-labelledby="league-table">
          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {tables.map(table => (
              <li key={table.id} className="col-span-1">
                <div className="">
                  <LeagueTable
                    id={table.id}
                    teamname={table.teamname}
                    highlightTeam="MTV Vorsfelde"
                    showRegion={table.showRegion}
                  />
                </div>
              </li>
            ))}
          </ul>

          <h3 className="mx-auto mt-8 max-w-6xl pb-2 text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
            Vergangene Jahre
          </h3>
          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            {tablesPast.map(table => (
              <li key={table.id} className="col-span-1">
                <LeagueTable
                  id={table.id}
                  teamname={table.teamname}
                  highlightTeam="MTV Vorsfelde"
                  showRegion={table.showRegion}
                />
              </li>
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  )
}

export default TablesPage
